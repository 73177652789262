import { useTranslation } from 'react-i18next';
import { PremiumFeatureModal } from '../PremiumFeatureModal';
import CSRDIMG from '../../../../assets/img/csrd.png';
import { CSRD_REPORTING_ARTICLE } from '../../../../constants';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';
import './styles.scss';

export const CSRDModal = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'premiumFeatureAdvise.modalInstances.csrd'
  });

  const flags = useFeatureFlags();

  return (
    <PremiumFeatureModal.Layout>
      {flags?.webinar241024 ? (
        <div className='premium-feature-modal__content'>
          <img
            style={{ width: '100%', height: 'auto' }}
            src={'/images/placeholders/descargaInformeCsrd.png'}
          />
          <a
            className='premium-feature-modal__download-button'
            href='https://xbrl.efrag.org/downloads/Annex-2-ESRS-Set1-illustrative-examples-of-XBRL-reports.zip'></a>
        </div>
      ) : (
        <>
          <PremiumFeatureModal.Title>
            <div className='flex gap-x-2'>
              <span>{t('title')}</span> <PremiumFeatureModal.ProTag />
            </div>
          </PremiumFeatureModal.Title>
          <PremiumFeatureModal.ImageContainer>
            <img src={CSRDIMG} style={{ maxWidth: '519px', height: 'auto' }} />
          </PremiumFeatureModal.ImageContainer>
          <PremiumFeatureModal.BulletPointContainer>
            <PremiumFeatureModal.BulletPoint
              title={t('bulletPoints.1.title')}
              description={t('bulletPoints.1.description')}
            />
            <PremiumFeatureModal.BulletPoint
              title={t('bulletPoints.2.title')}
              description={t('bulletPoints.2.description')}
            />
            <PremiumFeatureModal.BulletPoint
              title={t('bulletPoints.3.title')}
              description={t('bulletPoints.3.description')}
            />
          </PremiumFeatureModal.BulletPointContainer>
          <PremiumFeatureModal.Button.Container>
            <PremiumFeatureModal.Button.Schedule />
            <PremiumFeatureModal.Button.ReadMore url={CSRD_REPORTING_ARTICLE.es} />
          </PremiumFeatureModal.Button.Container>
        </>
      )}
    </PremiumFeatureModal.Layout>
  );
};
