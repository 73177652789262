import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js';
import 'chartjs-adapter-moment';
import { useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import numberToDecimal from '../../../utils/numberToDecimal';
import TooltipChart from '../tooltip/TooltipChart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler
);
function LineChartDoubleAxes({ labels, chartData }) {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [value, setValue] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);

  const chartRef = useRef(null);

  const options = {
    devicePixelRatio: 4,
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    stacked: false,
    interaction: {
      mode: 'index',
      intersect: false
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          color: 'rgba(25, 42, 62, 0.8)',
          font: {
            size: 8,
            family: 'Mulish',
            weight: 400,
            lineHeight: 1.2
          },
          maxTicksLimit: 12,
          minRotation: 0,
          maxRotation: 0
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          color: 'rgba(25, 42, 62, 0.8)',
          font: {
            size: 8,
            family: 'Mulish',
            weight: 400,
            lineHeight: 1.2
          }
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          color: 'rgba(25, 42, 62, 0.8)',
          font: {
            size: 8,
            family: 'Mulish',
            weight: 400,
            lineHeight: 1.2
          }
        }
      }
    },
    hover: {
      onHover: (e) => {
        var el = document.getElementById('canvas1');
        el.style.cursor = e[0] ? 'pointer' : 'default';
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,
        external: function (context) {
          if (chartRef && chartRef.current) {
            const tooltip = chartRef.current.tooltip;

            if (tooltip.opacity === 0) {
              // setShowTooltip(false);
            } else {
              // assuming your tooltip is `position: fixed`
              // set position of tooltip
              const position = context.chart.canvas.getBoundingClientRect();

              const left = position.left + window.scrollX + tooltip.caretX;
              const top = position.top + window.scrollY + tooltip.caretY - 20;

              setLeft(left);
              setTop(top);
              let value = tooltip.dataPoints[0].raw;

              let unit = 't CO₂ eq.';
              setValue(`${numberToDecimal(value)} ${unit}`);
              if (value) {
                setShowTooltip(true);
              }
            }
          }
        }
      }
    },
    font: {
      size: 8,
      family: 'Mulish',
      weight: 400,
      lineHeight: 1.2
    }
  };

  const data = {
    labels,
    datasets: chartData
  };

  const onMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <>
      {showTooltip && <TooltipChart text={value} top={top} left={left} />}
      <Line options={options} data={data} ref={chartRef} onMouseLeave={onMouseLeave} />
    </>
  );
}

export default LineChartDoubleAxes;
