import { IconName } from '../../../../types/utils/iconName';
import Icon from '../../icon/Icon';
import Label from '../../label/Label';
import '../styles.scss';
type Props = React.ComponentProps<typeof Label> & {
  icon?: IconName;
  showIcon?: boolean;
};

const SuccessLabel = ({ children, showIcon = true, icon = 'success', ...rest }: Props) => {
  return (
    <Label {...rest} lookAndFeel='success'>
      <div className='label-loading-wrapper'>
        {showIcon && <Icon icon={icon} color='white' size='medium' />}
        {children}
      </div>
    </Label>
  );
};

export default SuccessLabel;
