import Icon from '../../../../../../ui/icon/Icon';
import './styles.scss';

const TagStar = () => {
  return (
    <div className='icon-bg-color-star tag-star-ia'>
      <Icon icon='multi_star' color='blocked' />
    </div>
  );
};

export default TagStar;
