import { ReactComponent as EUTaxonomyES } from 'assets/img/eu_taxonomy_es.svg';
import { ReactComponent as EUTaxonomyEN } from 'assets/img/eu_taxonomy_en.svg';
import { ReactComponent as DMES } from 'assets/img/double_materiality_image_es.svg';
import { ReactComponent as DMEN } from 'assets/img/double_materiality_image_en.svg';
import InfographyES from 'assets/img/info_es.png';
import InfographyEN from 'assets/img/info_en.png';

export const PNGS = {
  es: {
    EUTaxonomy: EUTaxonomyES,
    DM: DMES,
    Infography: InfographyES
  },
  en: {
    EUTaxonomy: EUTaxonomyEN,
    DM: DMEN,
    Infography: InfographyEN
  }
} as const;
