import React, { useEffect, useState } from 'react';
import Modal from 'components/ui/modal/Modal';
import FrameworkCheckbox from './FrameworkCheckbox';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import logoLogicReturner from '../controlPanel/components/frameworkProgress/components/FrameworkDashboard/utils/logoLogicReturner';
import { useTranslation } from 'react-i18next';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import Button from 'components/ui/button/Button';
import useFrameworksPlans from 'customHooks/api/useFrameworksPlans';
import LoaderStandard from 'components/ui/loaders/loaderStandard/LoaderStandard';
import { FrameworkPlan } from 'services/api/frameworks';
import useSaveFrameworksPlans from 'customHooks/api/useSaveFrameworksPlans';
type FrameworksModalProps = {
  saveFrameworksHandler: () => void;
  modalOpen: boolean;
};

type FrameworkObject = {
  id: string;
  framework_id: string;
  plan_start_date: string;
  plan_end_date: string;
  purchased: boolean;
  visible: boolean;
  framework: {
    id: string;
    name: string;
    version: string;
  };
};

const FrameworksModal = ({ saveFrameworksHandler, modalOpen }: FrameworksModalProps) => {
  const { t } = useTranslation();
  const { availableFrameworks, frameworkPlans, frameworksLoading } = useFrameworksPlans();
  const [mutableFrameworkPlans, setMutableFrameworkPlans] = useState<FrameworkPlan[] | []>([]);
  useEffect(() => {
    // Merge frameworkPlans with availableFrameworks
    if (frameworkPlans && Array.isArray(frameworkPlans) && availableFrameworks) {
      const validFrameworkPlans = frameworkPlans.filter(Boolean);
      const newFrameworks = availableFrameworks
        .filter(
          (availableFramework) =>
            !validFrameworkPlans.some(
              (existingPlan) => existingPlan.framework_id === availableFramework.id
            )
        )
        .map((framework) => ({
          id: '',
          framework_id: framework.id,
          plan_start_date: new Date().toISOString().split('T')[0],
          plan_end_date: new Date().toISOString().split('T')[0],
          purchased: false,
          visible: false,
          framework: {
            id: framework.id,
            name: framework.name,
            version: framework.version
          }
        }));

      setMutableFrameworkPlans([...validFrameworkPlans, ...newFrameworks]);
    }
  }, [JSON.stringify(frameworkPlans), JSON.stringify(availableFrameworks)]);
  const handleFrameworkChange = (frameworkId: string, changes: Partial<FrameworkPlan>) => {
    setMutableFrameworkPlans((prev) =>
      prev.map((plan) => (plan.framework_id === frameworkId ? { ...plan, ...changes } : plan))
    );
  };
  const createFramework = (id: string): FrameworkObject => ({
    id,
    framework_id: id,
    plan_start_date: new Date().toISOString().split('T')[0],
    plan_end_date: new Date().toISOString().split('T')[0],
    purchased: false,
    visible: false,
    framework: {
      id,
      name: id,
      version: '1.0'
    }
  });

  const frameworksArray: FrameworkObject[] = [
    'ghg_protocol',
    'csrd',
    'miteco',
    'iso_14064',
    'sbti',
    'iso_14001',
    'cdp',
    'ecovadis',
    'iso_50001',
    'iso_45001'
  ].map(createFramework);

  const [mockFrameworkPlans, setMockFrameworkPlans] = useState<FrameworkPlan[]>(frameworksArray);

  const handleMockFrameworkChange = (frameworkId: string, changes: Partial<FrameworkPlan>) => {
    setMockFrameworkPlans((prev) =>
      prev.map((plan) => (plan.framework_id === frameworkId ? { ...plan, ...changes } : plan))
    );
  };
  const { saveFrameworkPlans } = useSaveFrameworksPlans();
  const handleSave = async () => {
    try {
      await saveFrameworkPlans(mutableFrameworkPlans);
      saveFrameworksHandler();
    } catch (error) {
      console.error('Error saving framework selections:', error);
    }
  };

  const getFrameworkPlanByName = (
    name: string | null | undefined,
    plans: FrameworkPlan[] | null | undefined
  ): FrameworkPlan | null => {
    if (!plans || !Array.isArray(plans) || !name) {
      return null; // Return null if plans is invalid or name is null/undefined
    }

    return plans.find((plan) => plan?.framework && plan.framework.name === name) || null;
  };
  return (
    <Modal.WithPortal
      show={modalOpen}
      onClose={saveFrameworksHandler}
      width='780px'
      maxWidth='780px'
      closeOnOutsideClick={false}>
      <h1 className='font-24 weight-600'>
        {t('controlPanel.frameworkProgress.configureFrameworksModal.title')}
      </h1>
      <h2 className='font-14 weight-400 mb-12'>
        {t('controlPanel.frameworkProgress.configureFrameworksModal.description')}
      </h2>
      {frameworksLoading ? (
        <LoaderStandard />
      ) : (
        <FormWrapper className='gap-4'>
          {frameworksArray.map((item) => {
            const frameworkPlan = getFrameworkPlanByName(
              item.framework.name,
              mutableFrameworkPlans
            );
            let frameworkPlanMock: FrameworkPlan | null = null;
            if (!frameworkPlan) {
              frameworkPlanMock = getFrameworkPlanByName(item.framework.name, mockFrameworkPlans);
            }
            const isBlocked = false; // Block if not visible
            const isChecked = frameworkPlan?.visible || frameworkPlanMock?.visible || false; // Show visibility status
            return (
              <FrameworkCheckbox
                key={item.id}
                title={t(`dashboard.frameworks.${item.id}.title`)}
                subtitle={t(`dashboard.frameworks.${item.id}.subtitle`)}
                logo={logoLogicReturner(item.id, isBlocked)}
                blocked={isBlocked}
                checked={isChecked}
                onChange={() => {
                  if (!isBlocked && frameworkPlan) {
                    handleFrameworkChange(frameworkPlan.framework_id, { visible: !isChecked });
                  } else if (!isBlocked && frameworkPlanMock) {
                    handleMockFrameworkChange(frameworkPlanMock.framework_id, {
                      visible: !isChecked
                    });
                  }
                }}
              />
            );
          })}
        </FormWrapper>
      )}
      <FormButtonSection className='mt-16'>
        <Button
          lookAndFeel='primary'
          text={t('controlPanel.frameworkProgress.configureFrameworksModal.save')}
          onClick={handleSave}
        />
      </FormButtonSection>
    </Modal.WithPortal>
  );
};

export default FrameworksModal;
