import { ReactElement } from 'react';
import './styles.scss';

type OwnProps = {
  text: ReactElement | string;
  position?: string;
};
type Props = OwnProps;

const TooltipCard = ({ text, position = 'top' }: Props) => {
  return (
    <div className='tooltip-card center'>
      <div className={`tooltip-wrapper ${position}`}>
        {position === 'top' && (
          <div className='tooltip-triangle'>
            <img src='/images/tooltipArrow.svg' alt='tooltip-arrow' />
          </div>
        )}
        <div className={'tooltip-modal tooltip-bg-color tooltip-chart-font on-dark-text-color'}>
          {text}
        </div>
      </div>
    </div>
  );
};

export default TooltipCard;
