export const DATA_QUALITY_THRESHOLD = 80;
export const SIZE_PAGINATION_BIG = 23;
export const SIZE_PAGINATION_SMALL = 10;
export const TONS_TO_SHOW_LATERAL_OFFSET_CARD = 4;
export const MAX_PAYLOAD_SIZE = 6; // 6 MB
export const MAX_INVOICES_PER_REQUEST = 12;
export const LCA_CALENDLY_URL = 'https://calendly.com/boris-dc/lca';
export const BOOK_MEETING_URL =
  'https://meetings-eu1.hubspot.com/javier-amador-cabrera/onau?embed=true';
export const PRIVACY_POLICY_URL = 'https://www.onau.eu/privacy-policy';
export const ADMIN_WHITE_LIST = [
  '@onau.eu',
  '@igeneris.com',
  'mario.simoes@edp.com',
  'raquel.pereiraafonso@edp.com',
  'diego@dcycle.io',
  'vinnicius@dcycle.io',
  'luis@dcycle.io',
  'boris@dcycle.io',
  'ricardo.mendez@dcycle.io',
  'cecilia@dcycle.io',
  'ana@dcycle.io',
  'daniel@dcycle.io',
  'juanma.taboada@dcycle.io',
  'almudena@dcycle.io'
];
export const LANGUAGES = ['en', 'es', 'pt'];
export const ACADEMY_URL = {
  es: 'https://www.onau.eu/academia',
  en: 'https://www.onau.eu/academy'
};
export const INTENSITY_METRIC_ARTICLE = {
  es: 'https://es.dcycle.io/post/metricas-de-intensidad',
  en: 'https://www.dcycle.io/post/intensity-metrics'
};
export const EVENTS_ARTICLE = {
  es: 'https://es.dcycle.io/post/how-dcycle-calculates-the-carbon-footprint-of-events',
  en: 'https://www.dcycle.io/post/how-dcycle-calculates-the-carbon-footprint-of-events'
};
export const VIDEOS = {
  tutorial: {
    es: '',
    en: '',
    pt: ''
  },
  lca: {
    es: '',
    en: '',
    pt: ''
  },
  socialAndGovernance: {
    es: '',
    en: '',
    pt: ''
  },
  funds: {
    es: '',
    en: '',
    pt: ''
  },
  logistics: {
    es: '',
    en: '',
    pt: ''
  },
  offset: {
    es: '',
    en: '',
    pt: ''
  },
  avoidedEmissions: {
    es: '',
    en: '',
    pt: ''
  },
  purchases: {
    es: '',
    en: '',
    pt: ''
  },
  sbti: {
    es: '',
    en: '',
    pt: ''
  },
  csrd: {
    es: '',
    en: '',
    pt: ''
  }
};

export const GLEC_ARTICLE = {
  es: 'https://scribehow.com/shared/Mide_tu_huella_en_empresas_de_logistica_bajo_el_marco_GLEC_v30__ZzztbxeHQUmU4-PYIzgB9Q',
  en: 'https://scribehow.com/shared/Mide_tu_huella_en_empresas_de_logistica_bajo_el_marco_GLEC_v30__ZzztbxeHQUmU4-PYIzgB9Q'
};

export const PURCHASES_HELP = {
  es: 'https://es.dcycle.io/post/how-and-why-to-measure-your-companys-purchases-understanding-the-methodology',
  en: 'https://www.dcycle.io/post/how-and-why-to-measure-your-companys-purchases-understanding-the-methodology'
};

export const DATADIS_HOW_TO = {
  es: 'https://dcycle-app-guias.webflow.io/datadis',
  en: 'https://dcycle-app-guias.webflow.io/datadis-en'
};

export const EMPLOYEE_FORM_GUIDE = {
  es: 'https://scribehow.com/shared/Como_rellenar_la_encuesta_de_movilidad__0rCPPXq-RxCXeINHN2Kl4Q',
  en: 'https://scribehow.com/shared/Como_rellenar_la_encuesta_de_movilidad__0rCPPXq-RxCXeINHN2Kl4Q'
};

export const CASE_OF_SUCCESS_BLUE_BANANA = {
  es: 'https://es.dcycle.io/clientes/blue-banana',
  en: 'https://www.dcycle.io/customer/blue-banana'
};

export const OFFSET_INFO = {
  es: 'https://es.dcycle.io/post/como-compensar-la-huella-de-carbono',
  en: 'https://www.dcycle.io/post/how-to-offset-the-carbon-footprint'
};

export const LCA_ARTICLE = {
  es: 'https://es.dcycle.io/post/benefits-of-life-cycle-analysis-for-companies-and-environment',
  en: 'https://www.dcycle.io/post/benefits-of-life-cycle-analysis-for-companies-and-environment'
};
export const KPI_14001_ARTICLE = {
  es: 'https://headwayapp.co/dcycle-changelog/iso-14001-y-kpis-ambientales-optimiza-el-desempe%C3%B1o-ambiental-de-tu-empresa-286798'
};

export const INVITE_SUPPLIERS_ARTICLE = {
  es: 'https://headwayapp.co/dcycle-changelog/controla-tu-cadena-de-suministo-invita-a-tus-proveedores-y-reduce-el-riesgo-294736'
};

export const CSRD_REPORTING_ARTICLE = {
  es: 'https://headwayapp.co/dcycle-changelog/prep%C3%A1rate-para-los-informes-csrd-simplificando-los-requisitos-obligatorios-con-dcycle-290312'
};

export const LEAN_AND_GREEN_ARTICLE = {
  es: 'https://headwayapp.co/dcycle-changelog/dcycle-te-ayuda-a-obtener-la-certificaci%C3%B3n-lean-green-292549'
};

export const DATAPOINTS_ARTICLE = {
  es: 'https://drive.google.com/file/d/1I0QlbQF0kck8WLJdwVAoRKmTA6VAPiBu/view'
};

export const CSRD_ARTICLE_INFO = {
  es: 'https://drive.google.com/file/d/1-JGYsoXrPVrCBcDGLqGbSa3_XVX9kb3W/view?usp=sharing'
};

export const DOUBLE_MATERIALITY_ARTICLE = {
  es: 'https://drive.google.com/file/d/1yIl0JLjgRiUFoB7uTiW64vsB0Prkys3y/view?usp=sharing'
};

export const METRICS_ARTICLE = {
  es: 'https://dcycle.notion.site/M-tricas-Dcycle-eb2f5cbd6751469eaaf55146d1482c2b',
  en: 'https://dcycle.notion.site/M-tricas-Dcycle-eb2f5cbd6751469eaaf55146d1482c2b',
  pt: 'https://dcycle.notion.site/M-tricas-Dcycle-eb2f5cbd6751469eaaf55146d1482c2b'
};

export const CSRD_ARTICLE = {
  es: 'https://es.dcycle.io/csrd',
  en: 'https://es.dcycle.io/csrd',
  pt: 'https://es.dcycle.io/csrd'
};

export const TOCS_LIST =
  'https://juniper-crab-033.notion.site/TOCs-5518114eb19f4e75b96f84255fb8c7a6';

export const MORE_INFO_OAXACA =
  'https://drive.google.com/file/d/1ERY-U_3jUu6mDxVsX57SH1C8PLIb4rO1/view';

export const MORE_INFO_REXENERA =
  'https://drive.google.com/file/d/1dWTu1Irz7BG6kPwNJqSUmP9aK_-Ny_ls/view';

export const BOOK_MEETING = 'https://www.dcycle.io/book-a-meeting-ana';
export const MORE_INFO_SBTI =
  'https://es.dcycle.io/post/the-lever-of-change-for-setting-emission-reduction-targets-science-based-targets-sbti';

export const SCOPES_ARRAY_STRING = ['1', '2', '3'];
export const SCOPES_ARRAY = [1, 2, 3] as const;

export const AVAILABLE_COLORS = [
  '#D2D7F3',
  '#76E4EA',
  '#3D32BA',
  '#1BB3BF',
  '#AEF1F3',
  '#4C72FF',
  '#648e94',
  '#5B5CE5',
  '#B2C0FF',
  '#1A8FA0',
  '#97BEFF',
  '#BED9FF',
  '#9DADF5',
  '#225e66',
  '#6E97FF',
  '#1D31B6',
  '#1F5E6B',
  '#7882EE',
  '#3D32BA',
  '#a784ff'
];

export const logisticsBlackList = [
  'e63edb02-5454-4c6c-918e-7c5c1c67b26f',
  '58190bba-689e-41ec-8ddc-18f51c4752e2'
];

export const DEBOUNCE_DELAY = 500;

export const ASK_FOR_LCA = 'https://www.dcycle.io/book-a-meeting-cecilia';
export const SBTI_OFFICIAL_FORM =
  'https://form.jotform.com/targets/commitment-submission-application';

export const SBTI_COMMITMENT_LETTER =
  'https://sciencebasedtargets.org/resources/files/SBT-Commitment-Letter.pdf';

export const SOURCE_OF_TRUTH_ARTICLE =
  'https://headwayapp.co/dcycle-changelog/nuevo-panel-de-control-en-dcycle-conecta-cualquier-dato-fuente-y-formato-297458';
