import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context/userContext';
import { Organization } from '../../../types/entities/organization';
import MainPageLayout from '../../layout/mainPageLayout/MainPageLayout';
import Button from '../../ui/button/Button';
import CheckboxMultiple from '../../ui/formComponents/checkboxMultiple/CheckboxMultiple';
import InfiniteList from '../../ui/infiniteList/InfiniteList';
import Modal from '../../ui/modal/Modal';
import SendEmail from './sendEmail/SendEmail';
// import './styles.scss';

type FormOrganizationData = {
  selectedOrganizations: string[];
  errors: ErrorType[];
};

const Emails = () => {
  const { t } = useTranslation();
  const url = `/organizations_list`;

  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const user = useContext(UserContext);
  const [total, setTotal] = useState(0);
  const [formData, setFormData] = useState<FormOrganizationData>({
    selectedOrganizations: [],
    errors: []
  });
  const [showSendEmail, setShowSendEmail] = useState(false);

  const onClickSendEmail = () => {
    setShowSendEmail(true);
  };

  const onCloseModal = () => {
    setShowSendEmail(false);
  };

  const removeAll = () =>
    setFormData((prev) => ({
      ...prev,
      selectedOrganizations: []
    }));

  const addAll = () =>
    setFormData((prev) => ({
      ...prev,
      selectedOrganizations: organizations.map((elem) => elem.id),
      errors: prev.errors.filter((error) => error.error !== 'list')
    }));

  const selectOrganization = (id: string) => () =>
    setFormData((prev) => {
      const newSelected = organizations?.find((elem) => elem.id === id);
      if (!newSelected) return prev;
      return {
        ...prev,
        selectedOrganizations: prev.selectedOrganizations.concat(newSelected.id),
        errors: prev.errors.filter((error) => error.error !== 'list')
      };
    });

  const removeOrganization = (id: string) => () =>
    setFormData((prev) => ({
      ...prev,
      selectedOrganizations: prev.selectedOrganizations.filter((elem) => elem !== id)
    }));

  const renderSelect = (id: string) => {
    return (
      <div className='icon-text-wrapper'>
        <CheckboxMultiple
          type='square'
          onSelect={selectOrganization(id)}
          selected={formData.selectedOrganizations.some((elem) => elem === id)}
          id={id}
          onRemove={removeOrganization(id)}
        />
      </div>
    );
  };

  const columns = [
    {
      title: (
        <div className='flex'>
          <CheckboxMultiple
            type='square'
            onSelect={addAll}
            selected={organizations.length === formData.selectedOrganizations.length}
            id='all'
            onRemove={removeAll}
          />
          <span>{t('sendEmailForm.selectAll')}</span>
        </div>
      ),
      dataIndex: 'select',
      key: 'select'
    },
    {
      title: t('organization.company_name'),
      dataIndex: 'company_name',
      key: 'company_name'
    },
    {
      title: t('organization.country'),
      dataIndex: 'country',
      key: 'country'
    },
    {
      title: t('organization.partner'),
      dataIndex: 'partner',
      key: 'partner'
    }
  ];

  const parseData = (organizations: Organization[]) =>
    organizations.map((organization) => ({
      ...organization,
      select: renderSelect(organization.id)
    }));

  return (
    <>
      <MainPageLayout
        sectionTitle={t('organization.title')}
        title={t('organization.start')}
        description={t('organization.startDescription')}>
        <InfiniteList
          i18key={'organization'}
          url={url}
          values={organizations}
          setValues={setOrganizations}
          columns={columns}
          parseData={parseData}
          organization={user?.selectedOrganization}
          total={total}
          setTotal={setTotal}
          filters={{
            inputTextKey: 'company_name',
            options: [
              {
                id: 'dcycle',
                name: 'dcycle'
              },
              {
                id: 'epd',
                name: 'epd'
              }
            ]
          }}
          buttons={
            <Button
              lookAndFeel={'primary'}
              onClick={onClickSendEmail}
              text={t('organization.sendEmail')}
              size={'small'}
              disabled={formData.selectedOrganizations.length === 0}
            />
          }
        />
        <Modal show={showSendEmail} onClose={onCloseModal}>
          <SendEmail organizations={formData.selectedOrganizations} onClose={onCloseModal} />
        </Modal>
      </MainPageLayout>
    </>
  );
};

export default Emails;
