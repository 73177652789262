import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getPrefixes, { getCountries } from 'constants/phonePrefixes';
import { Organization, ReportingPeriod } from 'types/entities/organization';
import apiFetch from 'utils/apiFetch';
import Button from 'components/ui/button/Button';
import InputText from 'components/ui/formComponents/inputText/InputText';
import Select from 'components/ui/formComponents/select/Select';
import {
  fiscalPeriodTranslationMapper,
  fiscalPeriodToSelectOption,
  selectOptionToFiscalPeriod,
  FiscalPeriodKeys
} from 'utils/fiscalYeari18nHandler';
import WarningLabel from 'components/ui/statusLabels/warningLabel/WarningLabel';
import { getMatricesParents } from 'services/api/matrices';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';

type Props = {
  editInvoiceFiscalData: (invoicingData: InvoicingDataType, fiscalData: ReportingPeriod) => void;
  organization: Organization;
  buttonText: string;
};
function EditFiscalData({ editInvoiceFiscalData, organization, buttonText }: Props) {
  const { t, i18n } = useTranslation();
  const flags = useFeatureFlags();
  const [tradeName, settradeName] = useState(organization.invoicing_trade_name || '');
  const [vat, setVat] = useState(organization.vat || '');
  const [address, setAddress] = useState(organization.invoicing_street_address || '');
  const [country, setCountry] = useState(
    organization.invoicing_country
      ? { id: organization.invoicing_country, name: organization.invoicing_country }
      : { id: '', name: '' }
  );
  const [province, setProvince] = useState(organization.invoicing_province || '');
  const [postalCode, setPostalCode] = useState(organization.invoicing_postal_code || '');
  const [fiscalYear, setFiscalYear] = useState(() => {
    const reportingPeriod = organization.reporting_periods?.find(
      (period) => period?.name === 'fiscal_year'
    );

    return (
      reportingPeriod || {
        id: '',
        name: 'fiscal_year',
        start_day: 0,
        start_month: 0,
        end_day: 0,
        end_month: 0
      }
    );
  });
  const [fiscalYearChanged, setFiscalYearChanged] = useState(false);
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const [matricesParents, setMatricesParents] = useState<{ total: number }>();
  const getMatricesParentsData = async () => {
    const matrices = await getMatricesParents(1, 10);
    setMatricesParents(matrices);
  };

  const selectedOrganization = useSelectedOrganization();
  useEffect(() => {
    getMatricesParentsData();
  }, [selectedOrganization?.id]);
  const onChangetradeName = (e: ChangeEvent<HTMLInputElement>): void => {
    const newErrors = errors.filter((elem) => elem.error !== 'tradeName');
    setErrors(newErrors);
    settradeName(e.target.value);
  };

  const onChangeVat = (e: ChangeEvent<HTMLInputElement>): void => {
    const newErrors = errors.filter((elem) => elem.error !== 'vat');
    setErrors(newErrors);
    setVat(e.target.value);
  };

  const onChangeAddress = (e: ChangeEvent<HTMLInputElement>): void => {
    const newErrors = errors.filter((elem) => elem.error !== 'address');
    setErrors(newErrors);
    setAddress(e.target.value);
  };

  const onChangeCountry = (value: SelectOptionFormat) => {
    const newErrors = errors.filter((elem) => elem.error !== 'country');
    setErrors(newErrors);
    setCountry(value);
  };

  const onChangeProvince = (e: ChangeEvent<HTMLInputElement>) => {
    const newErrors = errors.filter((elem) => elem.error !== 'province');
    setErrors(newErrors);
    setProvince(e.target.value);
  };

  const onChangePostalCode = (e: ChangeEvent<HTMLInputElement>) => {
    const newErrors = errors.filter((elem) => elem.error !== 'postalCode');
    setErrors(newErrors);
    setPostalCode(e.target.value);
  };

  const onChangeFiscalYear = (value: SelectOptionFormat) => {
    const newErrors = errors.filter((elem) => elem.error !== 'fiscalYear');
    setErrors(newErrors);
    setFiscalYear({
      ...selectOptionToFiscalPeriod(value.id as FiscalPeriodKeys),
      id: fiscalYear.id || ''
    });
    setFiscalYearChanged(true);
  };
  const handleEditFiscalData = async () => {
    const newErrors: ErrorType[] = [];

    if (!tradeName) {
      newErrors.push({ error: 'tradeName' });
    }

    if (!vat) {
      newErrors.push({ error: 'vat' });
    }

    if (!address) {
      newErrors.push({ error: 'address' });
    }

    if (!country.id) {
      newErrors.push({ error: 'country' });
    }

    if (!province) {
      newErrors.push({ error: 'province' });
    }

    if (!postalCode) {
      newErrors.push({ error: 'postalCode' });
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      setLoadingButton(true);
      const invoicingData: InvoicingDataType = {
        invoicing_trade_name: tradeName,
        vat,
        invoicing_street_address: address,
        invoicing_country: country.id,
        invoicing_province: province,
        invoicing_postal_code: postalCode
      };

      await apiFetch('patch', `/organizations/${organization.id}`, {
        ...invoicingData,
        country: organization.country // country is sent because it is needed to validate the vat in case it changes
      });
      let fiscalYearRes;
      if (fiscalYearChanged) {
        const { id, ...fiscalYearWithoutId } = fiscalYear; // Destructure to exclude `id`

        if (id) {
          fiscalYearRes = await apiFetch(
            'patch',
            `/sot_organization_reporting_period/${id}`,
            fiscalYearWithoutId
          );
        } else {
          fiscalYearRes = await apiFetch(
            'post',
            '/sot_organization_reporting_period',
            fiscalYearWithoutId
          );
        }
      } else {
        fiscalYearRes = { data: fiscalYear };
      }

      editInvoiceFiscalData(invoicingData, fiscalYearRes.data);

      setLoadingButton(false);
    } catch (error: any) {
      const newErrors = [...errors];
      if (
        error?.response?.data?.detail.find((elem: any) =>
          elem.msg.includes('INVALID_POSTAL_CODE_PASSED')
        )
      ) {
        newErrors.push({ error: 'postalCode', description: t('error.invalidPostalCode') });
      }
      if (
        error?.response?.data?.detail.find((elem: any) => elem.msg.includes('INVALID_VAT_PASSED'))
      ) {
        newErrors.push({ error: 'vat', description: t('error.invalidVat') });
      }
      setErrors(newErrors);
      setLoadingButton(false);
    }
  };

  return (
    <div className='edit-fiscal-data'>
      <h1 className='font-24 semibold-font mb-2'>{t('profile.cardInfo')}</h1>
      <h2 className='font-14 regular-font mb-8'>{t('profile.fiscalInfoModalDescription')}</h2>
      <div className='form-wrapper'>
        <InputText
          icon={'/images/icons/building.svg'}
          label={t('invoicingData.tradeName')}
          placeholder={t('invoicingData.tradeNamePlaceholder')}
          value={tradeName}
          onChangeValue={onChangetradeName}
          height='31px'
          fontClass='input-small-font'
          size='small'
          error={errors.find((elem) => elem.error === 'tradeName')}
        />
        <InputText
          icon={'/images/icons/creditcard.svg'}
          label={t('invoicingData.vat')}
          placeholder={t('invoicingData.vatPlaceholder')}
          value={vat}
          onChangeValue={onChangeVat}
          height='31px'
          fontClass='input-small-font'
          size='small'
          error={errors.find((elem) => elem.error === 'vat')}
        />
        <InputText
          icon={'/images/icons/map.svg'}
          label={t('invoicingData.address')}
          placeholder={t('invoicingData.addressPlaceholder')}
          value={address}
          onChangeValue={onChangeAddress}
          height='31px'
          fontClass='input-small-font'
          size='small'
          error={errors.find((elem) => elem.error === 'address')}
        />
        <Select
          icon={'/images/icons/pin.svg'}
          placeholder={t('facilities.writeFacilityCountry')}
          label={t('facilities.country')}
          value={{
            id: country.id,
            name:
              getPrefixes(i18n.resolvedLanguage).find((prefix) => prefix.code === country.id)
                ?.name || country.id
          }}
          onChangeValue={onChangeCountry}
          height='31px'
          fontClass='input-small-font'
          size='small'
          options={getCountries(i18n.resolvedLanguage, organization ? organization.country : 'ES')}
          error={errors.find((elem) => elem.error === 'country')}
          sort={false}
        />
        <InputText
          icon={'/images/icons/directions.svg'}
          label={t('invoicingData.province')}
          placeholder={t('invoicingData.provincePlaceholder')}
          value={province}
          onChangeValue={onChangeProvince}
          height='31px'
          fontClass='input-small-font'
          size='small'
          error={errors.find((elem) => elem.error === 'province')}
        />
        <InputText
          icon={'/images/icons/compass.svg'}
          label={t('invoicingData.postalCode')}
          placeholder={t('invoicingData.postalCodePlaceholder')}
          value={postalCode}
          onChangeValue={onChangePostalCode}
          height='31px'
          fontClass='input-small-font'
          size='small'
          error={errors.find((elem) => elem.error === 'postalCode')}
        />
        <Select
          iconV2='calendar'
          placeholder={t('profile.selectYourPeriod')}
          label={t('profile.fiscalYearStartEnd')}
          value={{
            id: fiscalPeriodToSelectOption(fiscalYear),
            name: t(`profile.fiscalPeriod.${fiscalPeriodToSelectOption(fiscalYear)}`)
          }}
          onChangeValue={onChangeFiscalYear}
          height='31px'
          fontClass='input-small-font'
          size='small'
          options={fiscalPeriodTranslationMapper()}
          error={errors.find((elem) => elem.error === 'fiscalYear')}
          sort={false}
          disabled={!!matricesParents && matricesParents?.total > 0}
          tooltipText={
            !!matricesParents && matricesParents?.total > 0
              ? t('profile.configMustBeDoneByMatrixCompany')
              : undefined
          }
          comment={
            fiscalPeriodToSelectOption(fiscalYear) !== ''
              ? t(`profile.fiscalYear.${fiscalPeriodToSelectOption(fiscalYear)}`)
              : ''
          }
        />
        {fiscalYearChanged && fiscalYear.id && (
          <WarningLabel showIcon={true}>
            <p className='text-left m-0'>{t('profile.fiscalYearUpdateNotice')}</p>
          </WarningLabel>
        )}
      </div>
      <div className='mt-8'>
        <Button
          lookAndFeel='primary'
          size='small'
          text={buttonText}
          onClick={handleEditFiscalData}
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default EditFiscalData;
