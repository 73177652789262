import { useTranslation } from 'react-i18next';
import { PremiumFeatureModal } from '../../../../../../../freeTrials/premiumFeatureModal/PremiumFeatureModal';
import { BOOK_MEETING, CSRD_REPORTING_ARTICLE } from '../../../../../../../../constants';
import Button from '../../../../../../../ui/button/Button';

export const CSRDModalPremium = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'esgMetrics.csrdModal'
  });

  return (
    <PremiumFeatureModal.Layout>
      <PremiumFeatureModal.Title>
        <div className='flex gap-x-2'>
          <span>{t('title')}</span> <PremiumFeatureModal.ProTag />
        </div>
      </PremiumFeatureModal.Title>
      <PremiumFeatureModal.ImageContainer>
        <img
          src={'/images/csrd_measure_illustration.png'}
          alt='CSRD'
          style={{ maxWidth: '519px', height: 'auto' }}
        />
      </PremiumFeatureModal.ImageContainer>
      <PremiumFeatureModal.BulletPointContainer>
        <PremiumFeatureModal.BulletPoint
          title={t('bulletPoints.1.title')}
          description={t('bulletPoints.1.description')}
        />
        <PremiumFeatureModal.BulletPoint
          title={t('bulletPoints.2.title')}
          description={t('bulletPoints.2.description')}
        />
        <PremiumFeatureModal.BulletPoint
          title={t('bulletPoints.3.title')}
          description={t('bulletPoints.3.description')}
        />
      </PremiumFeatureModal.BulletPointContainer>
      <PremiumFeatureModal.Button.Container>
        <Button
          lookAndFeel={'primary'}
          text={t('bookMeeting')}
          onClick={() => window.open(BOOK_MEETING, '_blank')}
          size='small'
        />
        <Button
          lookAndFeel={'secondary'}
          text={t('knowMore')}
          onClick={() => window.open(CSRD_REPORTING_ARTICLE.es, '_blank')}
          size='small'
        />
      </PremiumFeatureModal.Button.Container>
    </PremiumFeatureModal.Layout>
  );
};
