import { HTMLAttributes, ReactNode, ReactSVGElement, cloneElement } from 'react';
import {
  blocked_color,
  error_color,
  success_color,
  disabled_color,
  gray_color,
  gray_dark_color,
  main_bg_color,
  warning_color,
  primary_color,
  pending_color
} from '../../../styles/colors';
import { IconSize } from '../icon/Icon';
import SvgGradient from './components/SvgGradient';
import { getIconPx } from './utils/getIconPx';
import { getViewBox } from '../../../utils/getViewBox';

// POC

export type IconColor =
  | 'gray-dark'
  | 'gray'
  | 'white'
  | 'gradient'
  | 'blocked'
  | 'success'
  | 'error'
  | 'disabled'
  | 'warning'
  | 'pending';

const COLORS = {
  blocked: blocked_color,
  disabled: disabled_color,
  gray: gray_color,
  'gray-dark': gray_dark_color,
  white: main_bg_color,
  warning: warning_color,
  error: error_color,
  success: success_color,
  pending: pending_color
} as Record<IconColor, string>;

interface Props extends HTMLAttributes<SVGElement> {
  icon: ReactNode;
  color: IconColor;
  size?: IconSize;
}

const IconBase = ({ icon, color, size = 'medium', ...rest }: Props) => {
  const sizePx = getIconPx(size);
  const viewBox = getViewBox(size);

  if (process.env.REACT_APP_PARTNER === 'edp' && color === 'gradient')
    return cloneElement(icon as ReactSVGElement, {
      fill: primary_color,
      width: sizePx,
      height: sizePx,
      viewBox,
      ...rest
    });

  if (color === 'gradient') {
    return <SvgGradient icon={icon} size={size} {...rest} />;
  }

  if (color in COLORS) {
    return cloneElement(icon as ReactSVGElement, {
      fill: COLORS[color],
      width: sizePx,
      height: sizePx,
      viewBox,
      ...rest
    });
  }

  return <></>;
};

export default IconBase;
