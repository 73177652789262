import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import './styles.scss';
import { isKey } from 'utils/isKey';
import { PNGS } from './constants';

export const EsgMetricsInfography = () => {
  const { t, i18n } = useTranslation();

  const { Infography } = isKey(i18n.resolvedLanguage, PNGS) ? PNGS[i18n.resolvedLanguage] : PNGS.en;

  return (
    <div>
      <SectionHeader title={t('measureMain.esgMetrics')} breadcrumb={<Breadcrumb />} />
      <div className='infography-container'>
        <img src={Infography} className='infography-esg-metrics' />
      </div>
    </div>
  );
};
