import { fetchFrameworkPlans, FrameworkPlan } from 'services/api/frameworks';
import { useAvailableFrameworks } from './useAvailableFrameworks';
import { useQuery } from '@tanstack/react-query';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';

const useFrameworksPlans = (organizationId?: string) => {
  const org = useSelectedOrganization();
  const orgId = organizationId ?? org?.id;
  const {
    availableFrameworks,
    isLoading: loadingAvailableFrameworks,
    error: availableFrameworksError
  } = useAvailableFrameworks(orgId);
  const { data, isLoading, error } = useQuery<FrameworkPlan[], Error>({
    queryKey: ['frameworkPlans', orgId, JSON.stringify(availableFrameworks)],
    queryFn: () =>
      Promise.all(
        availableFrameworks?.map((framework) =>
          fetchFrameworkPlans(framework.id, orgId).catch(() => null)
        ) ?? []
      ),
    enabled: !!orgId && !!availableFrameworks
  });
  return {
    availableFrameworks,
    frameworkPlans: data,
    frameworksLoading: loadingAvailableFrameworks || isLoading
  };
};

export default useFrameworksPlans;
