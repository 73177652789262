import { useTranslation } from 'react-i18next';
import { PremiumFeatureModal } from '../PremiumFeatureModal';
import Iso14001IMG from '../../../../assets/img/iso_14001.png';
import { KPI_14001_ARTICLE } from '../../../../constants';

export const Iso14001Modal = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'premiumFeatureAdvise.modalInstances.iso14001'
  });

  return (
    <PremiumFeatureModal.Layout>
      <PremiumFeatureModal.Title>
        <div className='flex gap-x-2'>
          <span>{t('title')}</span> <PremiumFeatureModal.ProTag />
        </div>
      </PremiumFeatureModal.Title>
      <PremiumFeatureModal.ImageContainer>
        <img src={Iso14001IMG} style={{ maxWidth: '530px', height: 'auto' }} />
      </PremiumFeatureModal.ImageContainer>
      <PremiumFeatureModal.BulletPointContainer>
        <PremiumFeatureModal.BulletPoint
          title={t('bulletPoints.1.title')}
          description={t('bulletPoints.1.description')}
        />
        <PremiumFeatureModal.BulletPoint
          title={t('bulletPoints.2.title')}
          description={t('bulletPoints.2.description')}
        />
        <PremiumFeatureModal.BulletPoint
          title={t('bulletPoints.3.title')}
          description={t('bulletPoints.3.description')}
        />
      </PremiumFeatureModal.BulletPointContainer>
      <PremiumFeatureModal.Button.Container>
        <PremiumFeatureModal.Button.Schedule />
        <PremiumFeatureModal.Button.ReadMore url={KPI_14001_ARTICLE.es} />
      </PremiumFeatureModal.Button.Container>
    </PremiumFeatureModal.Layout>
  );
};
